export const copyEditorData = {
  subheading: "What is your ad about?",
  h1: "AI political ad copy co‑editor",
  label: "Choose ad tone",
  generate: "Generate ✨",
  tip: "Generate  social ad copy promoting this:",
  footer: "Terms and conditions | FAQs | Contact us",
  proponentLabel: "What we call ourselves",
  benefitLabel: "Issue that we are for",
  opponentLabel: "Our opponent(s)"
}
