<template>
  <v-container fluid>
    <v-row justify-start>
      <v-col lg="5" md="4" sm="12" xs="12" class="left">
        <v-row>
          <v-col>
            <h1 class="h1">{{ h1 }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <div class="subheading raleway-medium-black-18px">{{ subheading }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <form class="form">
              <div class="label">{{proponentLabel}}</div>
              <v-text-field
                v-model = form.proponent
                outlined
                dense
                background-color="white"
                width="300px"
              ></v-text-field>
              <div class="label">{{descriptionLabel}}</div>
              <v-text-field
                v-model = form.description
                outlined
                dense
                background-color="white"
              ></v-text-field>
              <div class="label">{{feelingLabel}}</div>
              <v-text-field
                v-model = form.feeling
                outlined
                dense
                background-color="white"
              ></v-text-field>
              <!-- <div class="label">{{aboutLabel}}</div>
              <v-text-field
                v-model = form.about
                outlined
                dense
                background-color="white"
              ></v-text-field> -->
              <div class="label">{{styleLabel}}</div>
              <v-radio-group
                v-model=form.style
                row
                class="radio-group"
              >
                <v-radio v-for="style in styleOptions" :key="style.value" :label="style.label" :value="style.value"></v-radio>
              </v-radio-group>
            </form>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="tip raleway-medium-black-18px">{{ tip }}</div>
          </v-col>
        </v-row>
        <v-row justify-end>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn
              class="button gigas"
              @click="submit"
              x-large
              depressed
            >{{generate}}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="footer" id="footer">{{ footer }} </div>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"></v-spacer>
      <v-col lg="5" md="6" sm="12" xs="12">
        <v-row>
          <v-col class="text-center mid-width">
            <v-alert
              v-if="error"
              color="red"
              type="error"
            >{{errorMessage}}</v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="nographics" v-if="!graphicsOptions.length && !waiting">
              <span v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">☝️☝️☝️</span>
              <span v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">👈👈👈</span>
              <br>Click the Generate button to create your first ad graphic! 🎉<br>
              <span v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">☝️☝️☝️</span>
              <span v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">👈👈👈</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center mid-width">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              class="progress"
              indeterminate
              v-if="waiting"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <graphic v-if="graphicsOptions.length" v-for="(graphic, index) in graphicsOptions" :graphicProps="graphicsOptions[index]" />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios"
import confetti from "canvas-confetti"
import Graphic from "./Graphic"
export default {
  name: "GraphicsEditor",
  components: {
    Graphic
  },
  props: [
    "h1",
    "subheading",
    "proponentLabel",
    "descriptionLabel",
    "feelingLabel",
    "aboutLabel",
    "styleLabel",
    "tip",
    "generate",
    "footer"
  ],
  data: () => ({
    form: {
      proponent: "Student",
      description: "wearing cap and gown",
      feeling: "hopeful",
      about: "student debt",
      style: "photorealistic"
    },
    styleOptions: [{
      value: "photorealistic",
      label: "Photorealistc 📸"
    },
    {
      value: "detailed",
      label: "Detailed 🎨"
    },
    {
      value: "claymation",
      label: "Claymation 😁"
    }
    ],
    waiting: false,
    error: false,
    errorMessage: "",
    graphicsOptions: []
  }),
  methods: {
    submit () {
      console.log(this.form)
      this.waiting = true
      axios.post("https://python-http-function-7f2uv7iasa-uc.a.run.app", this.form)
        .then((res) => {
          // Perform Success Action
          if (res.data) {
            console.log(res.data)
            this.graphicsOptions.unshift({ url: res.data })
            confetti()
          }
        })
        .catch((error) => {
          // error.response.status Check status code
          console.log(error)
          this.error = true
          this.errorMessage = (error.message + ". Please try again soon.")
        }).finally(() => {
          // Perform action in always
          this.waiting = false
          console.log("Done")
        })
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../variables'

.left
  background-color: orange
  border-radius: 0 90px 0 0
  box-shadow: 0px 4px 20px 5px #00000040

.h1
  font-family: $font-family-raleway
  font-size: $font-size-l
  font-weight: 700
  margin: auto 60px 0 0

.form
  align-items: flex-start
  border: 0.91px none
  display: flex
  flex-direction: column
  gap: 18.29px
  position: relative

.v-text-field
  min-width: 300px
  height: 40px

.label
  font-weight: 400
  height: 14px
.radio-group
  margin-top: 0
  height: 32px
.gigas
  background-color: $gigas!important
  color: $white

.footer
  font-family: $font-family-raleway
  font-size: $font-size-s
  font-style: italic
  font-weight: 100
  margin-top: 20px

.nographics
  color: #5A3092
  font-size: 34px
  margin-top: 100px
  max-width: 500px

.progress
  margin-bottom:40px

.mid-width
  max-width: 500px

</style>
