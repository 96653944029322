<template>
  <v-container fluid>
    <v-row justify-start>
      <v-col lg="5" md="4" sm="12" xs="12" class="left">
        <v-row>
          <v-col>
            <h1 class="h1">{{ h1 }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <div class="subheading raleway-medium-white-18px">{{ subheading }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <form class="form">
              <div class="label">{{proponentLabel}}</div>
              <v-text-field
                v-model = form.proponent
                outlined
                dense
                background-color="white"
                width="300px"
              ></v-text-field>
              <div class="label">{{benefitLabel}}</div>
              <v-text-field
                v-model = form.benefit
                outlined
                dense
                background-color="white"
              ></v-text-field>
              <div class="label">{{opponentLabel}}</div>
              <v-text-field
                v-model = form.opponent
                outlined
                dense
                background-color="white"
              ></v-text-field>
              <div class="label">Choose ad tone:</div>
                <v-select
                  v-model = form.tone
                  :items="toneOptions"
                  dense
                  outlined
                  background-color="white"
                  class="tone-select"
                ></v-select>
            </form>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="tip raleway-medium-white-18px">{{ tip }}</div>
          </v-col>
        </v-row>
        <v-row justify-end>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn
              color="orange"
              class="button"
              @click="submit"
              x-large
              depressed
            >{{generate}}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="footer" id="footer">{{ footer }} </div>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"></v-spacer>
      <v-col lg="5" md="6" sm="12" xs="12">
        <v-row>
          <v-col>
            <div class="nocopy" v-if="!copyOptions.length && !waiting">
              <span v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">☝️☝️☝️</span>
              <span v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">👈👈👈</span>
              <br>Click the Generate button to create your first ad copy! 🎉<br>
              <span v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">☝️☝️☝️</span>
              <span v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">👈👈👈</span>  
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center mid-width">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              class="progress"
              indeterminate
              v-if="waiting"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <copy v-if="copyOptions.length" v-for="(copyText, index) in copyOptions" :copyProps="copyOptions[index]" />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import confetti from "canvas-confetti"
import Copy from "./Copy"
export default {
  name: "CopyEditor",
  components: {
    Copy
  },
  props: [
    "subheading",
    "h1",
    "label",
    "generate",
    "tip",
    "footer",
    "proponentLabel",
    "benefitLabel",
    "opponentLabel"
  ],
  data: () => ({
    form: {
      proponent: "Democrats",
      benefit: "student debt relief",
      opponent: "Republicans",
      tone: "inspiring"
    },
    toneOptions: [
      {
        text: "Bold 💪",
        value: "inspiring"
      },
      {
        text: "Friendly 🙂",
        value: "friendly"
      },
      {
        text: "Funny 😅",
        value: "funny"
      }
    ],
    waiting: false,
    copyOptions: [],
  }),
  methods: {
    submit() {
      console.log(this.form)
      this.waiting = true
      axios.post('https://adtextgen-wdrqr6ze7a-uc.a.run.app', this.form)
                 .then((res) => {
                     //Perform Success Action
                     if (res.data) {
                       console.log(res.data.slice(2))
                       this.copyOptions.unshift({text: res.data.slice(2)})
                     }
                 })
                 .catch((error) => {
                     // error.response.status Check status code
                     console.log(error)
                 }).finally(() => {
                     //Perform action in always
                     this.waiting = false
                     confetti()
                     console.log("Done")
                 })
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../variables'

.left
  background-color: $gigas
  border-radius: 0 90px 0 0 
  box-shadow: 0px 4px 20px 5px #00000040

.h1
  color: $white
  font-family: $font-family-raleway
  font-size: $font-size-l
  font-weight: 700
  margin: auto 60px 0 0

.form
  align-items: flex-start
  border: 0.91px none
  display: flex
  flex-direction: column
  gap: 18.29px
  position: relative

.v-text-field
  min-width: 300px
  height: 40px

.label
  color: $white
  font-weight: 400
  height: 14px

.tone-select
  border: 0.91px none
  min-width: 274.38px
  position: relative
  padding-top: 2px
  margin-bottom: 6px
  font-size: 16px

.footer
  color: $white
  font-family: $font-family-raleway
  font-size: $font-size-s
  font-style: italic
  font-weight: 100
  margin-top: 20px

.nocopy
  color: #5A3092
  font-size: 34px
  margin-top: 100px
  max-width: 500px

.progress
  margin-bottom:40px

.mid-width
  max-width: 500px

</style>
