<template>
  <div>
    <v-row>
      <v-col>
        <img :src=graphicProps.url />
        <!-- <div class="copy-text raleway-medium-ship-gray-21-2px">
        {{ graphicProps.url }}
        </div> -->
      </v-col>
    </v-row>
    <v-row style="display:none">
      <v-col>
        <div class="buttons">
        <v-btn class="purple" depressed style="margin-right: 20px"><v-icon left>mdi-heart-outline</v-icon> Save</v-btn>
        <v-btn outlined><v-icon left>mdi-content-copy</v-icon> Copy</v-btn>
      </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <img
        class="hr"
        src="https://anima-uploads.s3.amazonaws.com/projects/631593b63cda19c4344df364/releases/63160f99205380b1c8d6fa4e/img/hr@2x.svg"
        />  
      </v-col>    
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Graphic",
  components: {
  },
  props: ["graphicProps"],
};
</script>

<style lang="sass">
.purple
  background-color: #6C63FF
  color: white!important

.copy-text
  cursor: pointer
  letter-spacing: -1.06px
  line-height: 33.6px
  margin-right: 1.42px

.buttons
  align-items: flex-start
  display: flex
  height: 32px
  margin-top: 17px
  min-width: 186px
  position: relative

.hr
  height: 1px
  margin-right: 1.42px
  margin-top: 10px
  margin-bottom: 20px
</style>
