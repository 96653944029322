export const graphicsEditorData = {
  h1: "AI political graphics co‑editor",
  subheading: "What is your ad image about?",
  proponentLabel: "Main character",
  descriptionLabel: "Describe them:",
  feelingLabel: "How they feel",
  aboutLabel: "About what?",
  styleLabel: "Style",
  tip: "Generate ad graphic promoting this:",
  generate: "Generate ✨",
  footer: "Terms and conditions | FAQs | Contact us"
}
