import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import "../styleguide.sass"
import "../globals.sass"
import vuetify from "./plugins/vuetify"

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://af7155f928d34586aa7678b255f31176@o1425499.ingest.sentry.io/6774056",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "brainstorm.creativechange.us", /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true
})

new Vue({
  render: h => h(App),
  vuetify,
  router
}).$mount("#app")
