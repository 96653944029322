import Vue from "vue"
import Router from "vue-router"
import CopyEditor from "./components/CopyEditor"
import GraphicsEditor from "./components/GraphicsEditor"
import { copyEditorData } from "./copyData"
import { graphicsEditorData } from "./graphicsData"

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/copy"
    },
    {
      path: "/copy",
      component: CopyEditor,
      props: { ...copyEditorData }
    },
    {
      path: "/graphics",
      component: GraphicsEditor,
      props: { ...graphicsEditorData }
    }
  ]
})
